var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"ma-4 pa-8"},[_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('v-select',{class:_vm.$vuetify.breakpoint.xs ? '' : 'mr-3',attrs:{"clearable":"","expanded":"","items":_vm.mapWarehouse,"label":"Warehouse","dense":"","item-text":"name","item-value":"id","outlined":""},model:{value:(_vm.query.warehouse),callback:function ($$v) {_vm.$set(_vm.query, "warehouse", $$v)},expression:"query.warehouse"}})],1),_c('v-col',{staticClass:"d-flex align-right",attrs:{"cols":"12","sm":"12","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"outlined":"","dense":"","color":"secondary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),_c('v-btn',{attrs:{"loading":_vm.loading},on:{"click":function($event){_vm.query.search = _vm.search}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Search ")],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex align-right",attrs:{"cols":"12","sm":"12","md":"2","lg":"3","xl":"3"}},[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"secondary","to":{ name: 'CreatePos' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" NEW POS ")],1)],1)],1),_c('p',{staticClass:"text-h6"},[_vm._v(" POS LIST ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.serialNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serialNumber || '-')+" ")]}},{key:"item.assetId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assetId || '-')+" ")]}},{key:"item.posId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.posId || '-')+" ")]}},{key:"item.branchModel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.brandModel || '-')+" ")]}},{key:"item.warehouse",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.warehouse.name || '-')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? item.status.toUpperCase() : '-')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{ name: 'EditPos', params: { id: item.id } },"small":"","outlined":"","color":"secondary"}},[_vm._v(" EDIT ")]),_c('v-btn',{attrs:{"to":{ name: 'PreviewPosData', params: { id: item.id } },"small":"","color":"secondary"}},[_vm._v(" VIEW ")])]}}])}),_c('div',{staticClass:"text-center mt-4"},[_c('v-pagination',{attrs:{"color":"secondary","length":_vm.totalPages},model:{value:(_vm.query.page),callback:function ($$v) {_vm.$set(_vm.query, "page", $$v)},expression:"query.page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }